<template>
  <div>
    <div v-if="content" class="max-w-1432 page-content-wrap px-4 mx-auto">
      <h2 v-html="content.title"></h2>
      <div class="content-text" v-html="content.content"></div>
    </div>

    <WebsiteBlocks :blocks="blocks" />
  </div>
</template>

<script>
import WebsiteBlocks from "../../../components/blocks/WebsiteBlocks";
export default {
  components: {WebsiteBlocks},
  data() {
    return {
      content: null,
      blocks: null,
    }
  },
  async beforeRouteUpdate(to, from, next) {
    console.log('before')
    next();
  },
  async created() {
    await this.fetchContent();
  },
  methods: {
    async fetchContent() {
      try {
        const splitted = this.$route.path.split('/');
        const url = splitted[splitted.length - 1];
        const { data } = await this.axios.get(`/content/${url}`);

        if (data.data) {
          this.content = {
            title: data.data.title,
            content: data.data?.content,
          };
          this.blocks = data.data?.blocks;
          if (data?.data?.btn && data.data.btn.link) {
            this.$emit('on-page-content-loaded', data.data.btn);
          }
        } else {
          this.$router.push('/error-page');
        }
      } catch (ex) {
        console.log('cant fetch content: ', ex);
        this.$router.push('/error-page');
      }
    }
  }
}
</script>
<style scoped lang='scss'>
.page-content-wrap {
  margin-top: 64px;
  margin-bottom: 86px;
}

.content-text {
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
}
</style>
